import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { viewBalance } from "../../../../Service/balance";
import { useNavigate } from "react-router-dom";
import { FaCoins, FaMoneyBill } from "react-icons/fa";
import { Tooltip } from 'react-tooltip';

const BalanceHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [preBalance, setPreBalance] = useState<any>(0);
  const [posBalance, setPosBalance] = useState<any>(0);

  const handleBalance = () => {
    const userData = localStorage.getItem("M2C:userData");

    if (!userData) {
      navigate(`${process.env.PUBLIC_URL}`);
    }

    const companyIdentifier = userData
      ? JSON.parse(userData)?.userInfo?.company_identifier
      : null;

    if (!companyIdentifier) {
      return;
    }

    viewBalance(companyIdentifier).then((response) => {
      console.log('Response balance:', response);

      if (!response || response.length <= 0) {
        const value = 0;
        setPreBalance(value);
        setPosBalance(value);
        return;
      }

      const prePaidBalance = response.find((item: any) => item.type === 'pre');

      console.log('prePaidBalance:', prePaidBalance);

      if (prePaidBalance) {
        setPreBalance(prePaidBalance.balance);
      }

      const posPaidBalance = response.find((item: any) => item.type === 'pos');

      console.log('posPaidBalance:', posPaidBalance);

      if (posPaidBalance) {
        setPosBalance(posPaidBalance.balance);
      }
    }).catch((error) => {
      const value = 0;
      setPreBalance(value);
      setPosBalance(value);
    });
  }

  useEffect(() => {
    handleBalance();
    
    const intervalId = setInterval(() => {
      handleBalance();
    }, 60000);
  
    return () => clearInterval(intervalId);
  }, []);

  const styleGeneral = {
    color: 'blue',
    fontSize: '16px',
    backgroundColor: 'white',
    padding: '10px',
    borderRadius: '0',
    fontWeight: 'normal',
  };

  const styleTitle = {
    color: 'black',
    fontSize: '16px',
    fontWeight: '500',
    marginLeft: '8px',
    marginRight: '8px',
  };

  return (
    <li style={styleGeneral}>
      <div className="d-flex profile-media align-items-center">
        <div id="pos-balance-tooltip" className="flex-grow-1">
          <span>
            <FaMoneyBill />
            <span style={styleTitle}>{t('balancePos')}:</span>
            {posBalance.toFixed(2)}
          </span>
        </div>
        <div id="pre-balance-tooltip" className="flex-grow-1">
          <span>
            <FaMoneyBill />
            <span style={styleTitle}>{t('balancePre')}:</span>
            {preBalance.toFixed(2)}
          </span>
        </div>
      </div>
      <Tooltip anchorId="pos-balance-tooltip" content={t('balancePosText')} place="bottom" style={{ backgroundColor: 'black', color: 'white' }} />
      <Tooltip anchorId="pre-balance-tooltip" content={t('balancePreText')} place="bottom" style={{ backgroundColor: 'black', color: 'white' }} />
    </li>
  );
};

export default BalanceHeader;
