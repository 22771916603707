import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import { useCallback, useEffect, useState } from "react";
import {useParams } from "react-router-dom";
import { format } from "date-fns";
import { listReportsCampaign, listReportsCampaignByRoot, listReportsDetailedCampaign, listReportsDetailedCampaignByRoot } from "../../../Service/reports";
import Chart from "react-google-charts";
import { SMSReportStatusEnum } from "../../../Enums/sms-report.status.enum";
import { Badges } from "../../../AbstractElements";
import { viewCampaign } from "../../../Service/campaign";

const SmsReportsDetailsCampaign = () => {
  const { t } = useTranslation();

  const [campaignData, setCampaignData] = useState<any>();

  const mainTitle = `${t("reports_detailed")} - ${campaignData?.name ?? ""} (${campaignData?.identifier ?? ""})`;
  
  const base = `${process.env.PUBLIC_URL}/campaigns`;

  const { campaign_identifier, company_identifier } = useParams<{ campaign_identifier: string, company_identifier: string }>();

  const userData = localStorage.getItem("M2C:userData");
  
  const companyType = userData ? JSON.parse(userData)?.userInfo?.company_type : null;

  const [detailedData, setDetailedData] = useState<any>([]);

  const [graphData, setGraphData] = useState<any>();

  const [statusTableData, setStatusTableData] = useState<any>([]);

  const [isLoading, setIsLoading] = useState(true);

  const [totalRows, setTotalRows] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);

  const perPage = 50;

  const handleCampaign = async () => {
    try {
      setIsLoading(true);
      if (!campaign_identifier || !company_identifier) {
        return;
      }

      const response = await viewCampaign(campaign_identifier, company_identifier);
      
      setCampaignData(response);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    } finally {
      setIsLoading(false);
    }
  }

  const StatusChip = ({ status }: any) => {
    switch (status) {
      case SMSReportStatusEnum.DELIVERED:
        return (
          <Badges className="graph-color-2 btn" color="transparant">
            {t("Delivered")}
          </Badges>
        );
      
      case SMSReportStatusEnum.UNDELIVERED:
        return (
          <Badges className="graph-color-5 btn" color="transparant">
            {t("Undelivered")}
          </Badges>
        );

      case SMSReportStatusEnum.SCHEDULED:
        return (
          <Badges className="graph-color-6 btn" color="transparant">
            {t("Scheduled")}
          </Badges>
        );

        case SMSReportStatusEnum.ENROUTE:
          return (
            <Badges className="graph-color-3 btn" color="transparant">
              {t("EnRoute")}
            </Badges>
          );

        case SMSReportStatusEnum.REJECTED:
          return (
            <Badges className="graph-color-8 btn" color="transparant">
              {t("Rejected")}
            </Badges>
          );

      case SMSReportStatusEnum.ACCEPTED:
        return (
          <Badges className="graph-color-9 btn" color="transparant">
            {t("Accepted")}
          </Badges>
        );

      case SMSReportStatusEnum.INVALID_NUMBER:
        return (
          <Badges className="graph-color-1 btn" color="transparant">
            {t("InvalidNumber")}
          </Badges>
        );

      case SMSReportStatusEnum.PROCESSING:
        return (
          <Badges className="graph-color-7 btn" color="transparant">
            {/* {t("Processing")} */}
            {t("sent")}
          </Badges>
        );

      case SMSReportStatusEnum.WAITING:
        return (
          <Badges className="graph-color-7 btn" color="transparant">
            {/* {t("Waiting")} */}
            {t("sent")}
          </Badges>
        );

      case SMSReportStatusEnum.EXPIRED:
        return (
          <Badges className="graph-color-4 btn" color="transparant">
            {t("Expired")}
          </Badges>
        );

      case SMSReportStatusEnum.ERROR:
        return (
          <Badges className="graph-color-13 btn" color="transparant">
            {t("NotSent")}
          </Badges>
        );

      case SMSReportStatusEnum.UNKNOWN:
      default:
        return (
          <Badges className="graph-color-11 btn" color="transparant">
            {t("Unknown")}
          </Badges>
        );
    }
  };

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      let response: any = [];

      if (companyType && companyType === "owner") {
        response = await listReportsCampaignByRoot(company_identifier, campaign_identifier);
      } else {
        response = await listReportsCampaign(company_identifier, campaign_identifier);
      }

      handleCampaign();

      setGraphData([
        ["Status", "Quantity"],
        [t("Delivered"), response.delivered ?? 0],
        [t("Undelivered"), response.undelivered ?? 0],
        [t("Waiting"), response.waiting ?? 0],
        [t("Scheduled"), response.scheduled ?? 0],
        [t("Enroute"), response.enroute ?? 0],
        [t("Rejected"), response.rejected ?? 0],
        [t("Accepted"), response.accepted ?? 0],
        [t("InvalidNumber"), response.invalidNumber ?? 0],
        [t("Processing"), response.processing ?? 0],
        [t("Unknown"), response.unknown ?? 0],
        [t("Expired"), response.expired ?? 0],
        [t("NotSent"), response.error ?? 0],
      ])

      setStatusTableData([
        {
          status: t("Processing"),
          quantity: response.processing,
        },
        {
          status: t("Waiting"),
          quantity: response.waiting,
        },
        {
          status: t("Delivered"),
          quantity: response.delivered,
        },
        {
          status: t("Undelivered"),
          quantity: response.undelivered,
        },
        {
          status: t("Scheduled"),
          quantity: response.scheduled,
        },
        {
          status: t("Enroute"),
          quantity: response.enroute,
        },
        {
          status: t("Rejected"),
          quantity: response.rejected,
        },
        {
          status: t("Accepted"),
          quantity: response.accepted,
        },
        {
          status: t("Unknown"),
          quantity: response.unknown,
        },
        {
          status: t("InvalidNumber"),
          quantity: response.invalidNumber,
        },
        {
          status: t("Expired"),
          quantity: response.expired,
        },
        {
          status: t("NotSent"),
          quantity: response.error
        },
      ]);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    } finally {
      setIsLoading(false);
    }

  }, [campaign_identifier, company_identifier]);

  const reviewColumns = [
    {
      name: t("Status"),
      selector: (row: any) => `${row.status}`,
      sortable: true,
    },
    {
      name: t("quantity"),
      selector: (row: any) => `${row.quantity}`,
      sortable: true,
    },
  ];

  const columns = [
    {
      name: t("company"),
      selector: (row: any) => `${row.company}`,
      sortable: true,
    },
    {
      name: t("campaign"),
      selector: (row: any) => `${row.campaign}`,
      sortable: true,
    },
    {
      name: t("phone_number"),
      selector: (row: any) => `${row.phone_number}`,
      sortable: true,
    },
    {
      name: t("status"),
      selector: (row: any) => row.status,
      cell: (row: any) => <StatusChip status={row.status} />,
      sortable: true,
    },
    {
      name: t("submit_date"),
      selector: (row: any) => `${row.submitDate ? format(row.submitDate, 'yyyy-MM-dd HH:mm') : ''}`,
      sortable: true,
    },
    {
      name: t("done_date"),
      selector: (row: any) => `${row.doneDate ? format(row.doneDate, 'yyyy-MM-dd HH:mm') : ''}`,
      sortable: true,
    },
    {
      name: t("cost"),
      selector: (row: any) => `${row.cost}`,
      sortable: true,
    },
    {
      name: t("service"),
      selector: (row: any) => `${row.service}`,
      sortable: true,
    },
    {
      name: t("gateway_message_id"),
      selector: (row: any) => `${row.gateway_message_id}`,
      sortable: true,
    },
    {
      name: t("message"),
      selector: (row: any) => `${row.message}`,
      sortable: true,
    },
  ];

  const graphOptions = {
    is3D: true,
    colors: [
      "#61AE41", 
      "#E61010",
      "#A1A2DB",
      "#44A8D7",
      "#CE1F91",
      "#3E01F5",
      "#FA5205",
      "#832F08",
      "#F6C761",
      "#F5A3A3",
      "#AD7102",
      "#0BB0B9",
    ],
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleDetailedData = async (currentPage: number, perPage: number) => {
    try {
      setIsLoading(true);
      let response: any = [];

      if (companyType && companyType === "owner") {
        response = await listReportsDetailedCampaignByRoot(company_identifier, campaign_identifier);
      } else {
        response = await listReportsDetailedCampaign(company_identifier, campaign_identifier, currentPage, perPage);
      }

      setDetailedData(response.data);
      setTotalRows(response.total);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    handleDetailedData(currentPage, perPage);
  }, [currentPage]);

  return (
    <>
      <Breadcrumbs
        base={base}
        mainTitle={mainTitle}
        parent={t("campaigns")}
      />
      <Container fluid>
        {isLoading ? (
          <Col sm="12">
            <Card>
              <CardBody>
                <div className="d-flex flex-column align-items-center">
                  <div
                    className="spinner-border text-primary position-relative"
                    role="status"
                    style={{
                      width: '10rem',
                      height: '10rem',
                      borderWidth: '0.65rem',
                    }}
                  >
                  </div>
                  <span
                      className="position-absolute top-50 start-50 translate-middle"
                      style={{
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        color: 'var(--bs-primary)',
                      }}
                    >
                      {t("loading")}
                    </span>
                </div>
              </CardBody>
            </Card>
          </Col>        
        ) : (
          <>
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <div className="d-flex justify-content-between">
                      <Col sm="5">
                        <div className="table-responsive">
                          <DataTable
                            columns={reviewColumns}
                            data={statusTableData}
                            highlightOnHover
                            striped
                          />
                        </div>
                      </Col>
                      <Col sm="7">
                        <div> 
                          <Chart
                            chartType="PieChart"
                            data={graphData}
                            options={graphOptions}
                            width={"100%"}
                            height={"400px"}
                          />
                        </div>
                      </Col>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <div className="table-responsive">
                    <DataTable
                      columns={columns}
                      data={detailedData}
                      progressPending={isLoading}
                      pagination
                      paginationServer
                      paginationTotalRows={totalRows}
                      onChangePage={handlePageChange}
                      paginationRowsPerPageOptions={[50]}
                      paginationPerPage={perPage}
                      paginationDefaultPage={currentPage}
                      subHeader
                      highlightOnHover
                      striped
                      persistTableHead
                    />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </>
  );
};

export default SmsReportsDetailsCampaign;
