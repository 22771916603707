import React from 'react';
import { Image, P } from "../../AbstractElements";

function MaintenancePage() {
  return (
    <div style={styles.container} className='login-card login-dark'>
      <img 
        src={`${process.env.PUBLIC_URL}/maintenance.png`} 
        alt="Site em manutenção" 
        style={styles.image}
      />
      
      <p style={styles.text}>
        Estamos atualmente realizando melhorias no sistema. Por favor, volte mais tarde.
      </p>
      <p style={styles.text}>Agradecemos pela paciência!</p>

      <img style={styles.logo} src={`${process.env.PUBLIC_URL}/assets/images/logo/logo-1.png`} alt="Logo" />
    </div>
  );
}

const styles: {
  container: React.CSSProperties;
  logo: React.CSSProperties;
  image: React.CSSProperties;
  title: React.CSSProperties;
  text: React.CSSProperties;
} = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#f5f5f5',
    fontFamily: 'Arial, sans-serif',
    textAlign: 'center',
    padding: '0 20px',
  },
  logo: {
    width: '150px',
    height: 'auto',
    marginBottom: '20px',
  },
  image: {
    textAlign: 'center',
    width: '60%',
  },
  title: {
    fontSize: '36px',
    color: '#333',
    marginBottom: '20px',
  },
  text: {
    marginTop: '20px',
    fontSize: '18px',
    color: '#666',
  },
};

export default MaintenancePage;
