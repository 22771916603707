import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { useTranslation } from "react-i18next";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useCallback, useEffect, useState } from "react";
import { ErrorMessage, Field, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Dropzone from "react-dropzone-uploader";
import { Typeahead } from "react-bootstrap-typeahead";
import { Btn } from "../../../AbstractElements";
import { useNavigate, useParams } from "react-router-dom";
import {
  listServices,
  sendCampaignByFile,
  sendCampaign,
  updateCampaign,
  viewCampaign,
} from "../../../Service/campaign";
import { listBlackList } from "../../../Service/blackList";

const ContainerSMSAccess = () => {
  const { t } = useTranslation();

  const base = `${process.env.PUBLIC_URL}/campaigns/sms`;
  const [openSave, setOpenSave] = useState<string>("1");
  const [open, setOpen] = useState<string>("1");
  const [open2, setOpen2] = useState<string>("1");
  const toggleSave = (id: any) =>
    open === id ? setOpenSave("1") : setOpenSave(id);
  const toggle = (id: any) => (open === id ? setOpen("1") : setOpen(id));
  const toggle2 = (id: any) => (open2 === id ? setOpen2("1") : setOpen2(id));
  const [type, setType] = useState("1");
  const [messageContent, setMessageContent] = useState(1);
  const [services, setServices] = useState<any>();
  const [selectedService, setSelectedService] = useState("");
  const [yourMessage, setYourMessage] = useState("");
  const [numbers, setNumbers] = useState("");
  const [scheduleDate, setScheduleDate] = useState("");
  const [scheduleTime, setScheduleTime] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [campaignName, setCampaignName] = useState("");
  const mainTitle = campaignName;
  const { id, name } = useParams<{ id: string; name: string }>();
  const userData = localStorage.getItem("M2C:userData");
  const identifier = userData
    ? JSON.parse(userData)?.userInfo?.company_identifier
    : null;
  const [loading, setLoading] = useState(false);
  const [blacklists, setBlacklists] = useState<any[]>([]);
  const [uploadedFile, setUploadedFile] = useState<any>(null);
  const [selectedBlacklists, setSelectedBlacklists] = useState<any[]>([]);

  const getUploadParams = () => {
    return { url: "https://httpbin.org/post" };
  };

  const navigate = useNavigate();

  const applyTimeMask = (value: string): string => {
    value = value.replace(/\D/g, "");

    if (value.length > 4) {
      value = value.slice(0, 4);
    }
  
    if (value.length > 2) {
      value = value.slice(0, 2) + ":" + value.slice(2);
    }
  
    return value;
  };

  const handleSubmit = (allFiles: any) => {
    const uploaded = allFiles[0].file;
    setUploadedFile(uploaded);
    allFiles.forEach((f: any) => f.remove());
    toast.success("Dropzone successfully submitted!");
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const handleServiceChange = (e: any) => {
    const { value } = e.target;
    setSelectedService(value);
  };

  const fetchViewCampaign = useCallback(async () => {
    try {
      const result2 = await listBlackList(identifier);
      
      const transformedList2 = result2.map((item: any) => ({
        name: item.name,
        identifier: item.identifier,
      }));

      setBlacklists(transformedList2);
      
      await listServices(identifier).then((e: any) => {
        setServices(e);
      });

      const response: any = await viewCampaign(id, identifier);
      
      setCampaignName(response.name);
    } catch (error) {
      console.error("Erro ao buscar campanha:", error);
      toast.error(t("error_campaign_get"));
    }
  }, [id, identifier, t]);

  const onChangeType = (e: any) => {
    setType(e);
  };

  const sendCampaignSMS = async () => {
    setLoading(true);

    try {
      await updateCampaign(
        {
          name: name,
          message: yourMessage,
          company_identifier: identifier,
          service_identifier: selectedService,
        },
        id,
        identifier
      );

      let isSchedule = false;

      if (uploadedFile) {
        const selectedBlacklistsIdentifiers = selectedBlacklists.map((blacklist: any) => blacklist.identifier);

        if (scheduleDate && scheduleDate !== "" && scheduleTime && scheduleTime !== "") {
          isSchedule = true;
        }
        
        await sendCampaignByFile(
          id, 
          identifier, 
          uploadedFile, 
          selectedBlacklistsIdentifiers,
          isSchedule,
          scheduleDate,
          scheduleTime,
          Intl.DateTimeFormat().resolvedOptions().timeZone,
        );

        let responseMessage = t("success_send_campaign");

        if (isSchedule) {
          responseMessage = t("success_schedule_campaign");
        }

        toast.success(responseMessage);

        navigate(`${process.env.PUBLIC_URL}/campaigns/sms`);
      } else if (numbers) {
        const selectedBlacklistsIdentifiers = selectedBlacklists.map((blacklist: any) => blacklist.identifier);
        
        if (scheduleDate && scheduleDate !== "" && scheduleTime && scheduleTime !== "") {
          isSchedule = true;
        }

        await sendCampaign(
          id, 
          identifier, 
          numbers, 
          selectedBlacklistsIdentifiers,
          isSchedule,
          scheduleDate,
          scheduleTime,
          Intl.DateTimeFormat().resolvedOptions().timeZone,
        );
        
        let responseMessage = t("success_send_campaign");

        if (isSchedule) {
          responseMessage = t("success_schedule_campaign");
        }

        toast.success(responseMessage);

        navigate(`${process.env.PUBLIC_URL}/campaigns/sms`);
      } else {
        toast.error(t("error_send_campaign"));
      }
    } catch (error: any) {
      console.error("Error sending campaign:", error);
      const errorMessage = error.response?.data?.message || t("error_saved");
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const [showNow, setShowNow] = useState(false);
  const [showLater, setShowLater] = useState(false);

  const handleNowChange = (e: any) => {
    setShowNow(e.target.checked);
    if (e.target.checked) setShowLater(false);
  };

  const handleLaterChange = (e: any) => {
    setShowLater(e.target.checked);
    if (e.target.checked) setShowNow(false);
  };

  useEffect(() => {
    if (id) {
      fetchViewCampaign();
    }
  }, [fetchViewCampaign, id]);

  const flushAccordionSave = (
    <>
      <Row>
        <Col>
          <Input
            name="now"
            type="radio"
            className="form-check-input"
            onChange={handleNowChange}
            checked={showNow === true}
          />
          <Label for="now" className="m-l-10">
            {t("now")}
          </Label>
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            name="later"
            type="radio"
            className="form-check-input"
            onChange={handleLaterChange}
            checked={showLater === true}
          />
          <Label for="later" className="m-l-10">
            {t("later")}
          </Label>
        </Col>
      </Row>
      {(showNow || showLater) && (
        <>
          {!showNow && (
            <Row>
              <Col>
                <Input
                  style={{ marginTop: "15px" }}
                  className="digits"
                  type="datetime-local"
                  defaultValue="2024-05-03T18:45:00"
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col xs="12" md="12">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "15px",
                }}
              >
                <Label check>{t("send_in_batches")}</Label>
                <Label className="switch">
                  <Input type="checkbox" name="charge_mo_processed" />
                  <span className="switch-state" />
                </Label>
              </div>
            </Col>
            <Col>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "15px",
                }}
              >
                <Label check>{t("batch_messages")}</Label>
                <Input type="number" style={{ width: "120px" }} />
              </div>
            </Col>
            <Col>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "15px",
                }}
              >
                <Label check>{t("gradual_increase")}</Label>
                <Input type="number" style={{ width: "120px" }} />
              </div>
            </Col>
            <Col>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "15px",
                }}
              >
                <Label check>{t("batchdays_between_batchesmessages")}</Label>
                <Input type="number" style={{ width: "120px" }} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                color="primary"
                type="submit"
                style={{ marginTop: "20px" }}
                onClick={toggleModal}
              >
                {t("save")}
              </Button>
            </Col>
          </Row>
        </>
      )}
    </>
  );

  const flushAccordion1 = (
    <Formik
      initialValues={{ type: "" }}
      validationSchema={Yup.object({
        // type: Yup.string().required("Tipo é obrigatório"),
      })}
      onSubmit={(values) => {
        console.log(values);
      }}
    >
      {({ handleChange, handleBlur, values, touched, errors }) => (
        <Form className="g-3 needs-validation custom-input">
          <Row>
            <Col xs="12" md="6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid #6A718533",
                  borderRadius: "8px",
                  height: "40px",
                  overflow: "hidden",
                }}
              >
                <div
                  // onClick={() => setMessageContent(0)}
                  style={{
                    backgroundColor:
                      messageContent === 0 ? "#5E61ED" : "#FFFFFF",
                    color: messageContent === 0 ? "#FFFFFF" : "#000000",
                    flex: 1,
                    textAlign: "center",
                    padding: "10px",
                    cursor: "pointer",
                    borderTopLeftRadius: "8px",
                    borderBottomLeftRadius: "8px",
                  }}
                >
                  {t("model")}
                </div>
                <div
                  onClick={() => setMessageContent(1)}
                  style={{
                    backgroundColor:
                      messageContent === 1 ? "#5E61ED" : "#FFFFFF",
                    color: messageContent === 1 ? "#FFFFFF" : "#000000",
                    flex: 1,
                    textAlign: "center",
                    padding: "10px",
                    cursor: "pointer",
                    borderTopRightRadius: "8px",
                    borderBottomRightRadius: "8px",
                  }}
                >
                  {t("personalized")}
                </div>
              </div>
              <FormGroup
                style={{
                  marginTop: "32px",
                  display: messageContent === 0 ? "block" : "none",
                }}
              >
                <Label check>{t("model")}</Label>
                <Input
                  type="select"
                  name="type"
                  className={`form-control ${
                    touched.type && errors.type ? "is-invalid" : ""
                  }`}
                  onChange={(e: any) => {
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  value={values.type}
                >
                  <option></option>
                  <option value="1">MODELO TESTE</option>
                  <option value="2">MODELO TESTE 2</option>
                </Input>
              </FormGroup>
              <FormGroup
                style={{
                  marginTop: messageContent === 1 ? "16px" : "0px",
                }}
              >
                <Label check>{t("your_message")}</Label>
                <Field
                  name="your_message"
                  as="textarea"
                  className={"form-control"}
                  onChange={(e: any) => {
                    setYourMessage(e.target.value);
                  }}
                  placeholder={t("your_message_placeholder")}
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="6" style={{ backgroundColor: "#E1DEDE" }}>
              <div
                style={{
                  backgroundColor: "#dcf8c6",
                  padding: "10px",
                  borderRadius: "10px",
                  boxShadow: "0 1px 4px rgba(0,0,0,0.3)",
                  maxWidth: "70%",
                  marginTop: "15px",
                  alignSelf: "flex-start",
                }}
              >
                {yourMessage}
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );

  const flushAccordion2 = (
    <Formik
      initialValues={{ type: "" }}
      validationSchema={Yup.object({
        // type: Yup.string().required("Tipo é obrigatório"),
      })}
      onSubmit={(values) => {
        console.log(values);
      }}
    >
      {({ handleChange, handleBlur, values, touched, errors }) => (
        <Form className="g-3 needs-validation custom-input">
          <Row>
            <Col xs="12" md="12">
              <FormGroup>
                <Label check>{t("select")}</Label>
                <Input
                  type="select"
                  name="type"
                  className={`form-control ${
                    touched.type && errors.type ? "is-invalid" : ""
                  }`}
                  onChange={(e: any) => {
                    handleChange(e);
                    onChangeType(e.target.value);
                  }}
                  onBlur={handleBlur}
                  value={values.type}
                >
                  <option></option>
                  <option value="1">{t("simple")}</option>
                  <option value="2">{t("list")}</option>
                </Input>
                <ErrorMessage
                  name="type"
                  component="span"
                  className="invalid-feedback"
                />
                <div
                  color=""
                  style={{
                    display: type ? "flex" : "none",
                    backgroundColor: "#EEEEFE",
                    marginTop: "15px",
                    padding: "8px",
                    gap: "8px",
                    borderRadius: "8px",
                    flexDirection: "row",
                    alignItems: "flex-start",
                  }}
                >
                  <img
                    src="/assets/svg/info.svg"
                    alt="check icon"
                    className="icon"
                    style={{ marginRight: "15px" }}
                  />
                  <p style={{ fontSize: "12px", color: "#5E61ED" }}>
                    {type === "1" ? t("info_text_simple") : t("info_text_list")}
                  </p>
                </div>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );

  const flushAccordion2_1 = (
    <Formik
      initialValues={{ service: "" }}
      validationSchema={Yup.object({
        // type: Yup.string().required("Tipo é obrigatório"),
      })}
      onSubmit={(values) => {
        console.log(values);
      }}
    >
      {({ handleBlur, touched, errors }) => (
        <Form className="g-3 needs-validation custom-input">
          <Row>
            <Col xs="12" md="12">
              <FormGroup>
                <Label check>{t("service")}</Label>
                <Input
                  type="select"
                  name="service"
                  className={`form-control ${
                    touched.service && errors.service ? "is-invalid" : ""
                  }`}
                  onChange={handleServiceChange}
                  onBlur={handleBlur}
                  value={selectedService}
                >
                  <option value=""></option>
                  {services &&
                    services.map((service: any) => (
                      <option
                        key={service.identifier}
                        value={service.identifier}
                      >
                        {service.name}
                      </option>
                    ))}
                </Input>
                <ErrorMessage
                  name="service"
                  component="span"
                  className="invalid-feedback"
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );

  const flushAccordion4 = (
    <Formik
      initialValues={{ numbers: "", skip_duplicate: false }}
      validationSchema={Yup.object({
        // numbers: Yup.string().required("Números para disparo é obrigatório"),
      })}
      onSubmit={(values) => {
        console.log(values);
      }}
    >
      {({ errors, touched }) => (
        <Form className="g-3 needs-validation custom-input">
          <Row>
            <Col
              xs="12"
              md="12"
              style={{ display: type === "2" ? "block" : "none", marginBottom: "25px" }}
            >
              <div
                style={{
                  marginTop: "15px",
                }}
              >
                <div>
                  <Dropzone
                    accept=".txt,.csv"
                    getUploadParams={getUploadParams}
                    onSubmit={handleSubmit}
                    maxFiles={1}
                  />
                  {uploadedFile && (
                    <div>Uploaded file: {uploadedFile.name}</div>
                  )}
                </div>
                <div>
                  {t('limit_contacts_per_campaign')}
                </div>
              </div>
            </Col>
            <Col
              xs="12"
              md="12"
              style={{ display: type === "1" ? "block" : "none" }}
            >
              <FormGroup>
                <Label check>{t("numbers_label")}</Label>
                <Input
                  type="textarea" 
                  name="numbers"
                  maxLength={160} 
                  className={`form-control ${
                    errors.numbers && touched.numbers
                      ? "is-invalid"
                      : touched.numbers
                      ? "is-valid"
                      : ""
                  }`}
                  placeholder={t("numbers_placeholder")}
                  onChange={(e: any) => {
                    setNumbers(e.target.value);
                  }}
                  value={numbers}
                />
                <div className="text-end small text-muted">
                  {numbers.length}/160 {t('characters')}
                </div> 
                {errors.numbers && touched.numbers && (
                  <span className="invalid-feedback">{errors.numbers}</span>
                )}
              </FormGroup>
            </Col>
            <Col xs="12" md="12">
              <FormGroup>
                <Label check>{t("block_label")}</Label>
                <Typeahead
                  labelKey="name"
                  options={blacklists}
                  multiple
                  id="selectTwo"
                  onChange={(selected) => {
                    setSelectedBlacklists(selected);
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );

  const flushAccordion5 = (
    <Formik
      initialValues={{ schedule_date: "", schedule_time: "" }}
      validationSchema={Yup.object({
        // numbers: Yup.string().required("Números para disparo é obrigatório"),
      })}
      onSubmit={(values) => {
        console.log(values);
      }}
    >
      {({ errors, touched }) => (
        <Form className="g-3 needs-validation custom-input">
          <Row>
            <Col
              xs="12"
              md="12"
              style={{ display: type === "2" ? "block" : "none", marginBottom: "25px" }}
            >
              <div
                style={{
                  marginTop: "15px",
                }}
              >
                <div>
                  
                </div>
              </div>
            </Col>
            <Col
              xs="12"
              md="12"
            >
              <FormGroup>
                <Label check>{t("schedule_date")}</Label>
                <Field
                  name="schedule_date"
                  type="date"
                  className={`form-control ${
                    errors.schedule_date && touched.schedule_date
                      ? "is-invalid"
                      : touched.schedule_date
                      ? "is-valid"
                      : ""
                  }`}
                  placeholder={t("schedule_date_placeholder")}
                  onChange={(e: any) => {
                    setScheduleDate(e.target.value);
                  }}
                  value={scheduleDate}
                />
                <ErrorMessage
                  name="schedule_date"
                  component="span"
                  className="invalid-feedback"
                />
              </FormGroup>
            </Col>
            <Col
              xs="12"
              md="12"
            >
              <FormGroup>
                <Label check>{t("schedule_time")}</Label>
                <Field
                  name="schedule_time"
                  type="text"
                  className={`form-control ${
                    errors.schedule_time && touched.schedule_time
                      ? "is-invalid"
                      : touched.schedule_time
                      ? "is-valid"
                      : ""
                  }`}
                  placeholder={t("schedule_time_placeholder")}
                  onChange={(e: any) => {
                    const formattedTime = applyTimeMask(e.target.value);
                    setScheduleTime(formattedTime);
                  }}
                  maxLength={5}
                  value={scheduleTime}
                />
                <ErrorMessage
                  name="schedule_time"
                  component="span"
                  className="invalid-feedback"
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );

  // O formato de disparo para seu SMS
  // O contato o qual se destina o seu SMS
  // The contact to whom your SMS is addressed
  // O serviço que sua campanha usará
  // The service your campaign will use
  // Caso queira agendar o envio da sua campanha
  // If you want to schedule the sending of your campaign
  // Crie e visualize o seu SMS
  // Create and view your SMS

  const flushList = [
    {
      id: "2",
      accordionHeaderClass: "bg-white",
      accordionHeading: t("sms_type"),
      accordionSubHeading: t("sms_type_text"),
      spanClass: "text-black",
      bodyText: flushAccordion2,
    },
    {
      id: "3",
      accordionHeaderClass: "bg-white",
      accordionHeading: t("service"),
      accordionSubHeading: t("campaign_service_text"),
      spanClass: "text-black",
      bodyText: flushAccordion2_1,
    },
    {
      id: "5",
      accordionHeaderClass: "bg-white",
      accordionHeading: t("recipient"),
      accordionSubHeading: t("recipient_text"),
      spanClass: "text-black",
      bodyText: flushAccordion4,
    },
    // {
    //   id: "6",
    //   accordionHeaderClass: "bg-white",
    //   accordionHeading: t("scheduling"),
    //   accordionSubHeading: t("scheduling_text"),
    //   spanClass: "text-black",
    //   bodyText: flushAccordion5,
    // },
  ];
  const flushList2 = [
    {
      id: "1",
      accordionHeaderClass: "bg-white",
      accordionHeading: t("message_content"),
      accordionSubHeading: t("message_content_text"),
      spanClass: "text-black",
      bodyText: flushAccordion1,
    },
  ];
  const flushListSave = [
    {
      id: "1",
      accordionHeaderClass: "bg-white",
      accordionHeading: "Agendar",
      accordionSubHeading: "Defina data e hora para começar",
      spanClass: "text-black",
      bodyText: flushAccordionSave,
    },
  ];

  return (
    <>
      <Breadcrumbs
        base={base}
        mainTitle={t(mainTitle)}
        parent={t("campaigns")}
      />
      <Container fluid>
        <Row>
          <Col xs="12" md="5">
            <Card>
              <CardBody>
                <Accordion flush open={open} toggle={toggle}>
                  {flushList.map((data) => (
                    <AccordionItem key={data.id}>
                      <AccordionHeader
                        targetId={data.id}
                        className={data.accordionHeaderClass}
                        style={{ borderBottom: "1px solid #dcdcdc" }}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                          >
                            <img
                              src="/assets/svg/circle-check.svg"
                              alt="check icon"
                              className="icon"
                              style={{ marginRight: "15px" }}
                            />
                            <span>{data.accordionHeading}</span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                          >
                            <img
                              src="/assets/svg/circle-check.svg"
                              alt="check icon"
                              className="icon"
                              style={{
                                marginRight: "15px",
                                visibility: "hidden",
                              }}
                            />
                            <p style={{ fontSize: "12px", color: "#959595" }}>
                              {data.accordionSubHeading}
                            </p>
                          </div>
                        </div>
                      </AccordionHeader>

                      <AccordionBody accordionId={data.id}>
                        {data.bodyText}
                      </AccordionBody>
                    </AccordionItem>
                  ))}
                </Accordion>
              </CardBody>
            </Card>
          </Col>
          <Col xs="12" md="7">
            <Card>
              <CardBody>
                <Accordion flush open={open2} toggle={toggle2}>
                  {flushList2.map((data) => (
                    <AccordionItem key={data.id}>
                      <AccordionHeader
                        targetId={data.id}
                        className={data.accordionHeaderClass}
                        style={{ borderBottom: "1px solid #dcdcdc" }}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                          >
                            <img
                              src="/assets/svg/circle-check.svg"
                              alt="check icon"
                              className="icon"
                              style={{ marginRight: "15px" }}
                            />
                            <span>{data.accordionHeading}</span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                          >
                            <img
                              src="/assets/svg/circle-check.svg"
                              alt="check icon"
                              className="icon"
                              style={{
                                marginRight: "15px",
                                visibility: "hidden",
                              }}
                            />
                            <p style={{ fontSize: "12px", color: "#959595" }}>
                              {data.accordionSubHeading}
                            </p>
                          </div>
                        </div>
                      </AccordionHeader>

                      <AccordionBody accordionId={data.id}>
                        {data.bodyText}
                      </AccordionBody>
                    </AccordionItem>
                  ))}

                  <Btn
                    color="primary"
                    type="submit"
                    style={{ marginTop: "20px" }}
                    onClick={sendCampaignSMS}
                    disabled={loading}
                  >
                    {loading ? t("loading") : t("submit")}
                  </Btn>
                </Accordion>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {isModalOpen && (
        <div className="custom-modal">
          <div className="custom-modal-content">
            <button className="close-button" onClick={toggleModal}>
              &times;
            </button>
            <div>
              <Accordion flush open={openSave} toggle={toggleSave}>
                {flushListSave.map((data) => (
                  <AccordionItem key={data.id}>
                    <AccordionHeader
                      targetId={data.id}
                      className={data.accordionHeaderClass}
                      style={{
                        borderBottom: "1px solid #dcdcdc",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <span>{data.accordionHeading}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <p style={{ fontSize: "12px", color: "#959595" }}>
                            {data.accordionSubHeading}
                          </p>
                        </div>
                      </div>
                    </AccordionHeader>

                    <AccordionBody accordionId={data.id}>
                      {data.bodyText}
                    </AccordionBody>
                  </AccordionItem>
                ))}
              </Accordion>
            </div>
          </div>
          <div className="custom-modal-overlay" onClick={toggleModal}></div>
        </div>
      )}

      <style>{`
        .custom-modal {
          position: fixed;
          top: 0;
          right: 0;
          width: 30%;
          height: 100%;
          background-color: white;
          border-radius: 8px 0 0 8px;
          box-shadow: -2px 0 10px rgba(0, 0, 0, 0.2);
          z-index: 1050;
          overflow-y: auto;
          transition: transform 3s ease-in-out;
          display: flex;
          flex-direction: column;
        }
        .custom-modal-content {
          padding: 20px;
          flex: 1;
        }
        .close-button {
          background: none;
          border: none;
          font-size: 24px;
          position: absolute;
          top: 10px;
          right: 20px;
          cursor: pointer;
          z-index: 99999;
        }
        .custom-modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 70%;
          height: 100%;
          background: rgba(0, 0, 0, 0.2);
          z-index: 1040;
        }
      `}</style>
    </>
  );
};

export default ContainerSMSAccess;
