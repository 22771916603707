import axios from "axios";
import store from "../ReduxToolkit/Store";

const API_URL = store.getState().apiUrl.url;

interface BalanceCreateRequest {
  company_identifier: string;
  balance: number;
  inputs: number;
  outputs: number;
}

interface BalanceUpdateRequest {
  company_identifier: string;
  balance: number;
  inputs: number;
  outputs: number;
}

interface BalanceDeleteRequest {
  company_identifier: string;
  identifier: string;
}

interface User {
  // Defina a estrutura do usuário conforme necessário
  company_identifier: string;
  identifier: string;
}

const getToken = () => {
  const userData = JSON.parse(localStorage.getItem("M2C:userData") || "{}");
  return userData?.token;
};

const getAuthHeaders = () => ({
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 403) {
      localStorage.removeItem("M2C:userData");
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    }
    return Promise.reject(error);
  }
);
const viewBalance = async (
  identifier: string,
): Promise<any[]> => {
  try {
    const response = await axios.get(`${API_URL}/balance/${identifier}`, getAuthHeaders());
    return response.data;
  } catch (error) {
    console.error("Error viewing balance", error);
    throw error;
  }
};

const listBalance = async (
  companyIdentifier: any,
  filters: {
    company_identifier?: string;
    type?: string;
    direction?: string;
    page?: number;
    limit?: number;
  } = {}
): Promise<any> => {
  try {
    const { type, direction, page = 1, limit = 10 } = filters;
    const params: any = {
      page,
      limit,
      order: "created_at",
    };

    if (type) {
      params.type = type;
    }

    if (direction) {
      params.direction = direction;
    }

    const response = await axios.get<any[]>(
      `${API_URL}/balance/owner/${companyIdentifier}`,
      {
        params,
        ...getAuthHeaders(),
      }
    );

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error("Transactions not found");
    } else {
      throw error;
    }
  }
};


export { viewBalance, listBalance };
