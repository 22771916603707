import { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import RouterData from './Routes';
import MaintenancePage from './Pages/Maintenance';

function App() {
  const [isMaintenance, setIsMaintenance] = useState(false);

  return (
    <>
      {isMaintenance ? (
        <MaintenancePage />
      ) : (
        <>
          <RouterData />
          <ToastContainer />
        </>
      )}
    </>
  );
}

export default App;
